

























































import { Component, Vue } from 'vue-property-decorator';
import { IBlacklist, IBlacklistCreate, IUserService, IBlacklistAddress } from '@/interfaces';
import VTextAreaWithValidation from '@/components/VTextAreaWithValidation.vue';
import VTextFieldWithValidation from '@/components/VTextFieldWithValidation.vue';
import VSelectWithValidation from '@/components/VSelectWithValidation.vue';
import VFileInputWithValidation from '@/components/VFileInputWithValidation.vue';
import { ValidationProvider } from 'vee-validate';
import {
  dispatchGetUserProfile,
  readUserProfile,
  dispatchCreateBlacklist,
  dispatchCreateBlacklistAddresses,
  dispatchUploadBlacklistAddressesFile,
} from '@/store/main/accessors';

@Component({
  components: {
    VFileInputWithValidation,
    VTextFieldWithValidation,
    VSelectWithValidation,
    VTextAreaWithValidation,
  },
})
export default class SendMessage extends Vue {
  public valid = false;
  public name = '';
  public service: string = '';
  public sender: string = '';
  public addresses: string = '';
  public addressesFile: File | null = null;
  public text: string = '';
  public servicesList = [];
  public checkSender: boolean = false;
  public sendersList = [];
  public services;
  public $refs!: {
    observer: InstanceType<typeof ValidationProvider>;
  };

  public async mounted() {
    await dispatchGetUserProfile(this.$store);
    const user = readUserProfile(this.$store);
    if (user) {
      this.services = new Map<string, IUserService>(
        user.services.map((item) => [item.service, item] as [string, IUserService]),
      );
      this.servicesList = Array.from(this.services.keys());
      await this.reset();
    }
  }

  public async reset() {
    this.name = '';
    this.service = '';
    this.sender = '';
    this.addresses = '';
    this.$refs.observer.reset();
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$refs.observer.validate()) {
      const blacklist: IBlacklistCreate = {
        name: this.name,
        service: this.service,
        sender: this.sender,
      };

      const result: IBlacklist = await dispatchCreateBlacklist(this.$store, blacklist);

      if (result?.id) {
        if (this.addressesFile) {
          const fileData: { id: number; file: File } = { id: result.id, file: this.addressesFile };
          await dispatchUploadBlacklistAddressesFile(this.$store, fileData);
        }

        const addresses = this.text.split('\n').filter((row) => row.trim());
        if (addresses.length > 0) {
          const body: IBlacklistAddress[] = [];
          for (const address of addresses) {
            body.push({ address });
          }
          const data: { id: number; body: IBlacklistAddress[] } = { id: result.id, body };
          await dispatchCreateBlacklistAddresses(this.$store, data);
        }

        this.$router.push({ name: 'main-blacklists-all' });
      }
    }
  }
}
